<template>
  <div>
    <page-header title='Cursos'></page-header>
    
    <v-row align="stretch" >

      <v-col cols="12" :lg="user.role.name == 'student' ? '12' : '7' ">
        <enrolled-courses></enrolled-courses>
      </v-col>
      <v-col cols="12" lg="12" v-if="user.role.name == 'student'">
        <concluded-courses></concluded-courses>
      </v-col>
      <v-col cols="12" :lg="user.role.name == 'student' ? '12' : '5' ">
        <v-card v-if="user.role.name != 'student'" outlined style='border-radius: 18px;' min-height="100%">
          <v-card-text class="pa-0" >
            <selected-course-card-actions :selected_course="selected_course_details" @closeDialog="handleCloseDialog" ></selected-course-card-actions>
          </v-card-text>
          <!--<v-card-text>
            <div class="grey--text title pa-3">
              Selecione ao lado um curso para ver detalhes.
            </div>
          </v-card-text>-->
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { mapActions, mapState, mapGetters } from 'vuex'
  export default {
    name: 'StudentCourseIndexList',

    components: {
      ConcludedCourses: () => import('./student_courses/ConcludedCourses.vue'),
      EnrolledCourses: () => import('./student_courses/EnrolledCourses.vue'),
      SelectedCourseCardActions: () => import('./shared/SelectedCourseCardActions.vue'),
    },
    
    data() {
      return {
        course_dialog: false,
        selected_course_details: {}
      }
    },

    computed: {
      ...mapState({
        user: state=> state.User.user
      })
    },
    
    methods: {
      handleCourseDetails(e){
        if(this.user.role.name == 'student'){
          this.course_dialog = true
          this.$router.push({name: 'ShowStudentCourse', params: {id: e.id, is_component_use_in_student_access: true}})
        }else{
          this.selected_course_details = e
        }
      },
      handleCloseDialog(){
        this.selected_course_details = {}
      },
      closeCourseDialog(){
        this.course_dialog = false
        this.$router.push({name: 'CourseList'})
      }
    },

  }
</script>

<style scoped>
  >>> .v-dialog {
    overflow: hidden;
    border-radius: 15px;
  }
</style>